.calendarControlToolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color:black;
    align-items: center;
}

.calendarControlMonthToggle {
    display: flex;
    align-items: center;
    color: black;
    background-color: #aaa;
    border-radius: 1rem;
}

.eventLogTaskSimple {
    padding-left: 10px;
    font-size: 1rem;
}

.eventLogTaskSimple {
    border-left: 5px solid white;
}

.eventLogTaskSimpleHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1em;
}

.eventLogEntry {
    padding-left: 20px;
}

.calendarMonthWeekdayLabel {
    width: 100%;
    text-align: center;
    background-color: #00366c;
    color: #fff;
    padding: 5px 0;
    font-size: 14px;
    top: 0;
    flex: 0 1 auto;
}

.calendarMonth {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5px;
}

.calendarWeek {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.calendarDayContainer {
    width: 14%;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid #ccc;
    background-color: #fff;
    position: relative;
    cursor: pointer;
}

.calendarDayContent {
    flex: 1 1 auto;
    width: calc(100% - 10px);
    padding: 5px;
}

.calendarDayPast {
    background-color: #ddd !important;
}

.calendarDayPast:hover {
    background-color: #bbb !important;
}

.calendarDayToday {
    background-color: #bdd1e5 !important;
}

.calendarDayToday:hover {
    background-color: #88b4e0 !important;
}

.calendarDayFuture:hover {
    background-color: #eee !important;
}

.calendarDayHeader {
    margin: 5px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: space-between;
}

.calendarDayWeekdayLabel {
    display: none;        
}

.calendarDayDayLabel {
    flex-grow: 1;
    text-align: right;
}

.calendarDayEmpty {
    cursor: pointer !important;
}

@media screen and (max-width: 600px) {
    .calendarMonthWeekdayLabel {
        display: none;
    }

    .calendarDayWeekdayLabel {
        display: contents;
    }

    .calendarDayEmpty {
        display: none !important;
    }

    .calendarWeek {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .calendarDayContainer {
        aspect-ratio: initial !important;
        width: 100% !important;
    }
}