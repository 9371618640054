.loginModal {
    width: 700px;
}

.loginModalBanner {
    max-height: 25vh;
}

.loginFormField {
    margin-top: 8px !important;
}

@media screen and (max-width: 600px) {
    .loginModal {

    }
}