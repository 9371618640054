.calendar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5px;
}

.calendarOptions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: right;
    color: black;
    align-items: center;
}

.editButton {
    cursor: pointer;
}

.taskEventIndicators {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.taskEventIndicators .taskGroupings {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2px;
}

.taskEventIndicators .dot {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
}

.dot:hover {
    width: 100%;
    border-radius: 35px;
}

.text {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.dot:hover .text {
    visibility: visible;
    opacity: 1;
}

.streakBar {
    width: 100%;
    height: 10px;
}

.dayEventGroup {
    padding-left: 10px;
}

.taskEventLogEntry:hover {
    background-color: #DDD;
}

.tasklogBody {
    max-height: 300px;
    overflow-y: scroll;
}

.eventLogGroup {
    border: 1px solid black;
    margin: 5px;
    background-color: #eee;
}

.eventLogGroup:hover {
    border: 1px solid black;
    margin: 5px;
    background-color: #aaa;
}

.eventLogGroupHeading {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.eventLogGroupTitle {
    font-size: 30px;
    flex-grow: 1;
}

.eventLogEntry {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eventLogEntry > * {
    padding: 5px;
}

.eventLogHistoryQuickView {
    display: flex;
    flex-direction: column;
}

.eventLogHistoryQuickView .row {
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
}

.rowLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: center;
}

.rowRight {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-self: flex-end;
    align-items: center;
}

.eventLogCompletionMark {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.eventLogToDoMark {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.refreshButton {
    transition: transform 1s ease-in-out; /* Smooth animation */
}
  
.rotated {
  transform: rotate(360deg);
}