.App {
  background-color: #3e393f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.root {
    height: 100%;
}

.appTopNav, .appTopNav > *, .appActionButtons > * {
    background-color: #084C61 !important;
    color: white !important;
}

.appActionButtons {
    flex-grow: 1;
    text-align: right;
}

.modalBox {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: 2px solid black;
    box-shadow: 24px;
    background: #eef;
    max-width: 95%;
    max-height: 90%;
}

.appActionButtons {
    flex-grow: 1;
}
