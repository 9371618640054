.assets_progressBadgeContainer {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    position: relative;
    font-size: .65em;
    padding: 0 5pxs;
    cursor:default;
}

.assets_progressBadgeContainer .assets_progressBadgeProgress {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    border-radius: 10px;
}

.assets_progressBadgeContainer  .assets_progressBadgeTitle {
    flex: 1 1 0;
    z-index: 1;
    overflow: hidden;
    text-wrap: nowrap;
    padding-left: 5px;
}

.assets_progressBadgeContainer  .assets_progressBadgeCount {
    flex: 0 0 1;
    z-index: 1;
    text-align: right;
    padding-right: 5px;
}