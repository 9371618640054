.taskListModal, .calendarOptionsModal {
    background-color: white;
    border-radius: 1em;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.taskListModal > div {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
}

.taskListModal > div > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.taskListModal > div > div:nth-child(2) {
    width: 75%;
}

.taskCard {
    text-align: left;
    width: 100%;
    padding: 2px 0;
    background-color: transparent !important;
}

.taskStatusSelectorChip {
    margin: 3px 0 !important;
}

.taskCardContents {
    position: relative;
    display: flex;
    flex-direction: column;
    color:  white;
    font-size: 16px;
    padding: 0;
    background-color: #666;
}

.goodHabit > *:not(.cardHeader) {
    border-left: 5px solid #D1F0B1;
}

.neutralHabit > *:not(.cardHeader) {
    border-left: 5px solid #AAA;
}

.badHabit > *:not(.cardHeader) {
    border-left: 5px solid #FCB9B2;
}

.cardHeader {
    background-color: #333;
    display: flex;
    align-items: center;
}

.cardHeader .checkbox {
    padding: 5px !important;
}

.cardHeader .categoryIcon {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: right;
}

.taskCardContents > * {
    padding-left: 10px;
    padding-right: 10px;
}

.taskCardActions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 !important;
    background-color: white;
}

.taskActionButtonContainer > div, .taskActionButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskEditForm {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.taskEditField {
    width: 100%;
    margin: 10px 0;
}

.taskEventModal > *{
    padding: 5px 0 !important;
}

.statusSelector {
    background-color: lightgray;
    border-radius: 1em;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
}

.taskContentFooter {
    position: absolute;
    bottom: 0;
    right:0;
}

.mainContent {
    background-color: #3e393f;
    padding: 5px;
    overflow-y: auto;
    max-height: 100%;
    flex-grow: 1;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.mainContent::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.taskListMain {
    color: white;
    height: 100%;
    background-color: #3e393f;
}

.taskListActionBar {
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.taskListActionBar > *{
    flex: 1 1 auto;
}

.taskEventEditor > * {
    padding: 0 !important;
}

.taskEventEditorActions {
    width:100%;
    display: flex;
    justify-content: space-between;
}

.taskEventEditorActions {
    width:100%;
    display: flex;
    justify-content: space-between;
}

.taskEventEditorActions > *, .taskCardActions > div, .taskEditModalActions > * {
    margin: 0 5px;
    flex-grow: 1;
    text-align: center;
}

.taskEditTagSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tagCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left-width: 7px;
    border-left-style: solid;
}

.tagCardName {
    flex-grow: 1;
}

.tagCardIcon {
    margin: 0 5px;
}

.tagCardName {
    padding-right: 1em;
}

.tagEditColor {
    cursor: pointer;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
}

.tagEditCard {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

.tagEditCard > div {
    display: flex;
    flex-direction: row;
}

.tagNameField {
    flex-grow: 3 !important;
}

.tagEditCard > div > * {
    flex-grow: 1;
    margin: 0 5px;
}

.tagEditCardButton {
    text-align: center;
}